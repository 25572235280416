import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaSeedling } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				піцерії Bella Vita
			</title>
			<meta name={"description"} content={"Насолоджуйтесь автентичним смаком Італії"} />
			<meta property={"og:title"} content={"піцерії Bella Vita"} />
			<meta property={"og:description"} content={"Насолоджуйтесь автентичним смаком Італії"} />
			<meta property={"og:image"} content={"https://cryptoluxstar.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cryptoluxstar.com/img/01345534.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cryptoluxstar.com/img/01345534.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cryptoluxstar.com/img/01345534.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cryptoluxstar.com/img/01345534.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://cryptoluxstar.com/img/01345534.png"} />
			<meta name={"msapplication-TileImage"} content={"https://cryptoluxstar.com/img/01345534.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://cryptoluxstar.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Піцерії Bella Vita
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Ласкаво просимо до піцерії Bella Vita, де кожен шматочок піци переносить вас у кулінарну подорож до самого серця Італії. Наша пристрасть до створення найавтентичніших та найсмачніших піц поєднується з нашим прагненням забезпечити теплу та затишну атмосферу для наших гостей. Незалежно від того, чи прийшли ви сюди на швидкий обід, затишну вечерю або святкову зустріч, Bella Vita - це ідеальне місце, щоб розслабитися і насолодитися трапезою з родиною та друзями.
				</Text>
				<Link
					href="/contact-us"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-primary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Відвідати нас
				</Link>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			lg-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-26"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				max-width="1200px"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				lg-justify-content="center"
			>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					Чому варто обрати піцерію Bella Vita?
				</Text>
				<Text
					margin="8px 0px 0px 0px"
					font="--base"
					text-align="center"
					color="--dark"
					lg-max-width="720px"
					sm-text-align="left"
				>
					У піцерії Bella Vita ми пишаємося тим, що використовуємо лише найсвіжіші та найякісніші інгредієнти.
				</Text>
				<Box
					display="grid"
					width="100%"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="24px"
					margin="36px 0px 0px 0px"
					max-width="1000px"
					md-grid-template-columns="1fr"
				>
					<Box
						padding="24px 24px 48px 24px"
						background="#f9fff9"
						border-radius="8px"
						border-width="0px 0px 0px 4px"
						border-style="solid"
						border-color="--color-primary"
						box-shadow="--m"
						sm-padding="24px 24px 24px 24px"
					>
						<Box display="flex" align-items="center">
							<Icon category="fa" icon={FaSeedling} size="28px" color="--primary" />
							<Text margin="0px 0px 0px 12px" font="--lead" color="--dark">
							Автентичні рецепти
							</Text>
						</Box>
						<Text margin="16px 0px 0px 0px" font="--base" color="--dark">
						Наші піци виготовляються за традиційними італійськими рецептами, що передаються з покоління в покоління.
						</Text>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						background="#f9fff9"
						border-radius="8px"
						border-width="0px 0px 0px 4px"
						border-style="solid"
						border-color="--color-primary"
						box-shadow="--m"
						sm-padding="24px 24px 24px 24px"
					>
						<Box display="flex" align-items="center">
							<Icon category="fa" icon={FaSeedling} size="28px" color="--primary" />
							<Text margin="0px 0px 0px 12px" font="--lead" color="--dark">
							Свіжі інгредієнти
							</Text>
						</Box>
						<Text margin="16px 0px 0px 0px" font="--base" color="--dark">
						Ми використовуємо лише найсвіжіші інгредієнти місцевого виробництва, щоб гарантувати найвищу якість.
						</Text>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						background="#f9fff9"
						border-radius="8px"
						border-width="0px 0px 0px 4px"
						border-style="solid"
						border-color="--color-primary"
						box-shadow="--m"
						sm-padding="24px 24px 24px 24px"
					>
						<Box display="flex" align-items="center">
							<Icon category="fa" icon={FaSeedling} size="28px" color="--primary" />
							<Text margin="0px 0px 0px 12px" font="--lead" color="--dark">
							Затишна атмосфера
							</Text>
						</Box>
						<Text margin="16px 0px 0px 0px" font="--base" color="--dark">
						Наша піцерія створена для того, щоб забезпечити теплу та гостинну атмосферу для всіх наших гостей.
						</Text>
					</Box>
					<Box
						padding="24px 24px 48px 24px"
						background="#f9fff9"
						border-radius="8px"
						border-width="0px 0px 0px 4px"
						border-style="solid"
						border-color="--color-primary"
						box-shadow="--m"
						sm-padding="24px 24px 24px 24px"
					>
						<Box display="flex" align-items="center">
							<Icon category="fa" icon={FaSeedling} size="28px" color="--primary" />
							<Text margin="0px 0px 0px 12px" font="--lead" color="--dark">
							Кваліфіковані кухарі
							</Text>
						</Box>
						<Text margin="16px 0px 0px 0px" font="--base" color="--dark">
						Наші кухарі люблять піцу і мають багаторічний досвід у створенні ідеального пирога.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="16px 16px 16px 16px"
				lg-order="-2"
				lg-width="100%"
				width="100%"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					overflow-x="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						position="absolute"
						display="block"
						width="100%"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://cryptoluxstar.com/img/2.jpg"
						object-fit="cover"
						top="auto"
						right={0}
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				width="40%"
				align-items="flex-start"
			>
				<Box
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 60% 0px"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						width="100%"
						right={0}
						min-height="100%"
						top="auto"
						left={0}
						bottom="0px"
						src="https://cryptoluxstar.com/img/3.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				flex-direction="row"
				flex-wrap="wrap"
				width="60%"
				lg-width="100%"
				lg-order="-1"
			>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="100%"
					flex-direction="column"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="8px 16px 0px 16px"
				>
					<Text
						lg-text-align="center"
						sm-font="--headline3"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--headline2"
						md-text-align="left"
					>
						Наша філософія
					</Text>
				</Box>
				<Box
					align-items="flex-start"
					lg-width="100%"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					width="50%"
					flex-direction="column"
					padding="16px 16px 16px 16px"
					display="flex"
					sm-margin="0px 0px 0px 0px"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--grey"
						font="--base"
						lg-text-align="center"
						sm-margin="0px 0px 30px 0px"
						md-text-align="left"
					>
						Наша місія проста: донести автентичні смаки Італії до нашої громади. Ми віримо, що чудова піца починається з чудових інгредієнтів, і ми закуповуємо їх у місцевих фермерів та виробників, коли це можливо. 
					</Text>
				</Box>
				<Box
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="16px 16px 16px 16px"
					display="flex"
					lg-align-items="center"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="50%"
					flex-direction="column"
					lg-padding="0px 16px 16px 16px"
				>
					<Text
						lg-text-align="center"
						sm-margin="0px 0px 30px 0px"
						margin="0px 0px 0px 0px"
						color="--grey"
						font="--base"
						md-text-align="left"
					>
						Ми віддані принципам сталого розвитку і прагнемо мінімізувати свій вплив на навколишнє середовище за допомогою екологічно чистих практик на нашій кухні та в їдальні. У Bella Vita ми не просто готуємо піцу - ми створюємо спогади та традиції, які будуть зберігатися протягом багатьох років.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});